/* for loader center on differnt screen */
.Loader {
  text-align: center;
  /* margin-left: 80%; */
  margin-top: 20%;
}
.small-contained-button {
  background-color: #37cd77 !important;
}
.search-button {
  background-color: #37cd77 !important;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  border: 1px solid #37cd77;
}
.pointer {
  cursor: pointer;
}

@media (max-width: 767px) {
  .Loader {
    text-align: center;
    margin-top: 70%;
  }
}
